<template>
  <!-- 联系我们-组件 -->
  <div class="contact-us">
    <div class="contact-us-main">
      <img
        class="logo"
        src="https://s1.ax1x.com/2020/07/27/aPDbVJ.png"
        alt=""
      />

      <div class="info">
        <p>
          <svg-icon class="icon" icon-class="rf-icon_dianhua" /> 电话:
          020-32285850
        </p>
        <p class="qq">
          <svg-icon class="icon" icon-class="rf-icon_qq" /> QQ: 781878169
        </p>
        <p>
          <svg-icon class="icon" icon-class="rf-icon_e-mail" /> 邮箱:
          service@realfinance.com.cn
        </p>
        <p>
          <svg-icon class="icon" icon-class="rf-icon_dizhi" /> 地点:
          广州市天河区建工路4号PCI未来社区
        </p>
        <p class="enName">
          <svg-icon class="icon" icon-class="rf-ico_wangzhan" /> 网址:
          http://www.realfinance.com.cn
        </p>
      </div>
    </div>

    <tencent-map />
  </div>
</template>

<script>
import tencentMap from "@/components/local/tencent-map"; // 瑞友简介-组件

export default {
  name: "contactUs",
  components: {
    tencentMap,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.contact-us {
  width: 1200px;
  padding: 40px 30px 0;
  margin: 0 auto;

  .contact-us-main {
    display: flex;

    .logo {
      width: 160px;
      height: 160px;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      margin-left: 40px;
      color: var(--main-memo_gray);

      .icon {
        color: var(--sub-title_gray);
      }
    }
  }
}
</style>
