<template>
  <!-- 关于我们 -->
  <div>
    <!-- 产品介绍 -->
    <product-brief
      height="340"
      :title="currentData && currentData.productBrief.title"
      :memo="currentData && currentData.productBrief.memo"
      :img-src="currentData && currentData.productBrief.imgSrc"
    />

    <!-- 产品详细介绍-横幅 -->
    <horizontal-bar
      v-for="(item, index) in currentData && currentData.horizontalBars"
      :key="index"
      v-bind="item"
    >
      <!-- 瑞友简介 -->
      <rfIntroduce v-if="item.rfIntroduce" />

      <!-- 瑞友文化 -->
      <rfCulture v-if="item.rfCulture" />

      <!-- 联系我们 -->
      <contact-us v-if="item.contactUs" />
    </horizontal-bar>
  </div>
</template>

<script>
import rfIntroduce from "./components/rf-introduce"; // 瑞友简介-组件
import rfCulture from "./components/rf-culture"; // 瑞友文化-组件
import contactUs from "./components/contact-us"; // 联系我们-组件

export default {
  components: { rfIntroduce, rfCulture, contactUs },
  data() {
    return {
      currentData: {
        productBrief: {
          title: "关于我们",
          memo: [
            "金融科技领域专业型服务商",
            "将AI应用于金融业务的领导者",
            "”金融+教育”国家级示范实验室的创新者",
          ],
          imgSrc: "https://s1.ax1x.com/2020/07/27/aPDxxK.png",
        }, // 介绍组件数据
        horizontalBars: [
          {
            grayTitle: true,
            title: "瑞友简介",
            subTitle: "RealFinance introduction",
            rfIntroduce: true,
          },
          {
            title: "瑞友文化",
            subTitle: "RealFinance Culture",
            extraBg: true,
            style: {
              background: "url(https://s1.ax1x.com/2020/07/27/aPrMZQ.png)",
            },
            rfCulture: true,
          },
          {
            grayTitle: true,
            title: "联系我们",
            subTitle: "Contact Us",
            contactUs: true,
          },
        ], // 横幅内容
      },
    };
  },
  methods: {
    downloadFile(file) {
      console.log("下载文件", file);
    },
  },
};
</script>

<style></style>
