<template>
  <!-- 瑞友简介-组件 -->
  <div class="rf-introduce">
    <p
      v-for="(item, index) in introduce"
      :key="index"
      v-text="item"
      class="introduce-item"
    />
  </div>
</template>

<script>
export default {
  name: "rfIntroduce",
  data() {
    return {
      introduce: [
        "广州瑞友(RealFinance)，即广州瑞友信息技术有限公司，系中国领先的金融科技软件服务商，致力于AI+金融的创新与场景实现。",
        "广州瑞友秉承“以数字技术驱动移动化、场景化，推进金融服务数字化、智能化，实现智慧金融、智慧数据、智慧教育”，以“客户最信赖、成长最快速、竞争力最强的中国金融管理信息化企业”为公司愿景，以“软件重在服务，服务重在价值”发展战略，即利用新技术成果，推出产品化服务，将不断满足用户需求、不断提升用户体验、不断体现使用价值作为公司生存之本。我司现已具备高度凝聚力的团队和高效的研发机制、打造精品的能力，并坚定推动事业合伙人机制建设，我们的目标是成为“服务、分享、开放、专业、高效”的科技企业。",
        "广州瑞友致力为金融机构提供开放的微服务架构，推出了知识图谱、智能问答机器人、RPA、新一代管理会计、银行智能化产品定价、智能采购管理系统、FVR金融仿真实验教学平台等产品及解决方案。",
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.rf-introduce {
  width: 1200px;
  margin: 0 auto;
  text-align: justify;
  padding: 30px;

  .introduce-item {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 40px;
    color: var(--main-memo_gray);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
