<template>
  <!-- 联系我们-组件 -->
  <div id="tencent-map"></div>
</template>

<script>
export default {
  name: "tencentMap",
  data() {
    return {};
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      var center = new TMap.LatLng(23.141862, 113.341965); //设置中心点坐标

      var map = new TMap.Map("tencent-map", {
        pitch: 0,
        zoom: 12,
        center,
      });

      //初始化marker
      var marker = new TMap.MultiMarker({
        id: "marker-layer", //图层id
        map: map,
        styles: {
          //点标注的相关样式
          marker: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src:
              "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png",
          }),
        },
        geometries: [
          {
            //点标注数据数组
            id: "demo",
            styleId: "marker",
            position: center,
            properties: {
              title: "marker",
            },
          },
        ],
      });

      var infoWindow = new TMap.InfoWindow({
        map,
        position: center, //设置信息框位置
        content: "广州瑞友信息技术有限公司", //设置信息框内容
      });
    },
  },
};
</script>

<style lang="less" scoped>
#tencent-map {
  width: 100%;
  height: 240px;
  color: #000;
  margin-top: 30px;
}
</style>
