<template>
  <!-- 瑞友文化-组件 -->
  <div class="rf-culture">
    <div class="culture-item" v-for="(item, index) in cultureData" :key="index">
      <img class="item-icon" :src="item.icon" alt="" />
      <p class="item-title" v-text="item.title" />
      <p class="item-memo" v-text="item.memo" />
    </div>
  </div>
</template>

<script>
export default {
  name: "rfCulture",
  props: {},
  data() {
    return {
      cultureData: [
        {
          icon: "/icons/icon_freedom.png",
          title: "自由",
          memo: "Free",
        },
        {
          icon: "/icons/icon_dl.png",
          title: "独立",
          memo: "independent",
        },
        {
          icon: "/icons/icon_bf.png",
          title: "奔放",
          memo: "Unrestrained",
        },
        {
          icon: "/icons/icon_zy.png",
          title: "专业",
          memo: "Professional",
        },
        {
          icon: "/icons/icon_zz.png",
          title: "专注",
          memo: "Focus",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.rf-culture {
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: 0 auto;
  padding: 40px 30px 0;
  display: flex;
  justify-content: space-between;

  .culture-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;

    .item-icon {
      max-width: 60px;
      max-height: 60px;
      margin-bottom: 10px;
    }

    .item-title {
      line-height: 28px;
    }
  }
}
</style>
